import axios from "axios";
import qs from 'qs';

const controller = new AbortController();

const axiosInstance = axios.create({
	signal: controller.signal,
	validateStatus: (status) => (status >= 200 && status < 300) || status === 422,
	paramsSerializer: params => {
		return qs.stringify(params, { arrayFormat: "repeat" })
	}
});

axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			controller.abort();
		}
		return Promise.reject(error.response);
	}
);

export { axiosInstance as HttpClient };
